import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { ApiComunicator } from './Helpers/ApiComunicator';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; 


const root = ReactDOM.createRoot(document.getElementById('root'));
const usuarioID = localStorage.getItem('user_id');


// // Verificar si el navegador soporta notificaciones
// if ('Notification' in window) {
//   if (Notification.permission !== 'granted') {
//     Notification.requestPermission().then((permission) => {

//       if (permission === 'granted') {
//         console.log('Permiso para notificaciones ok.');

//         // Obtener la suscripción del usuario
//         navigator.serviceWorker.ready.then((registration) => {

//           console.log("registration", registration)
//           registration.pushManager.getSubscription().then((subscription) => {

//             if (subscription) {
//               console.log("existe", subscription)
//               // La suscripción ya existe, la guardo en localStorage
//               const subscriptionJSON = JSON.stringify(subscription);
//               localStorage.setItem('pushSubscription', subscriptionJSON);

//             } else {
//               console.log("no existe")
//               // No hay una suscripción existente, creo una
//               const applicationServerKey = 'BEc01N3rBYmMMV6JUiFaEWNl6Mm4By0puAPaccY1raL2XsWTqqE5cBDpt3gAfeM3f-H5dpA6X0gMWbVN9H0xNNs';
              
//               registration.pushManager
//                 .subscribe({
//                   userVisibleOnly: true,
//                   applicationServerKey:  urlBase64ToUint8Array(applicationServerKey),
//                 })
//                 .then((newSubscription) => {
//                   const subscriptionJSON = JSON.stringify(newSubscription);
//                   localStorage.setItem('pushSubscription', subscriptionJSON);
//                 })
//                 .catch((error) => {
//                   console.error('Error al suscribirse a las notificaciones:', error);
//                 });

//             }
//           });
//         });
//       }
//     });
//   }
// }

// function urlBase64ToUint8Array(base64String) {
//   const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
//   const base64 = (base64String + padding)
//     .replace(/\-/g, '+')
//     .replace(/_/g, '/');

//   const rawData = atob(base64);
//   const buffer = new Uint8Array(rawData.length);

//   for (let i = 0; i < rawData.length; i++) {
//     buffer[i] = rawData.charCodeAt(i);
//   }

//   return buffer;
// }

//   const usuariosNotificaciones = async () => {
  
//     try {
//       let body = {usuarioID}
//       console.log(body)
//       await ApiComunicator('/notificaciones/necesitaNotificacion', body, "POST")
//       .then(response => {
//         console.log(response)
//         if (response.necesitaNotificacion == true) 
//         {
//           console.log("okkkk")
//           showNotification()
//         } else {
//           console.log("no necesita noti")
//         }

//       })
//       .catch(function (error) {
//         console.log(error)
//       });
//     }
//     catch (error) {
//       console.log(error)
//     }
// }



  // function ejecutarUsuariosNotificacionesCadaDosMinutos() {
  //   function ejecutar() {
  //     if (usuarioID != undefined) {
  //       usuariosNotificaciones();
  //     }
  //   }
  
  //   ejecutar();
  
  //   setInterval(ejecutar, 5 * 60 * 1000);
  // }
  
  // ejecutarUsuariosNotificacionesCadaDosMinutos();

  // function ejecutarUsuariosNotificacionesDiariamente() {
  //   const ahora = new Date();
  //   const horaActual = ahora.getHours();
  //   const minutosActuales = ahora.getMinutes();
  
  //   if (horaActual < 16) {
  //     const tiempoHastaCuatroPM = (16 - horaActual) * 60 * 60 * 1000 - minutosActuales * 60 * 1000;
  
  //     setTimeout(() => {
  //       usuariosNotificaciones();
  //       ejecutarUsuariosNotificacionesDiariamente();
  //     }, tiempoHastaCuatroPM);
  //   } else {
  //     const tiempoHastaManana = (24 - horaActual + 16) * 60 * 60 * 1000 - minutosActuales * 60 * 1000;
  //     setTimeout(() => {
  //       usuariosNotificaciones();
  //       ejecutarUsuariosNotificacionesDiariamente();
  //     }, tiempoHastaManana);
  //   }
  // }
  
  // ejecutarUsuariosNotificacionesDiariamente();

  // function sendNotificationToSubscriber() {
  //   const subscriptionJSON = localStorage.getItem('pushSubscription');
  //   if (subscriptionJSON) {
  //     const subscription = JSON.parse(subscriptionJSON);
  
  //     if ('serviceWorker' in navigator && 'PushManager' in window) {
  //       navigator.serviceWorker.ready.then((registration) => {
  //         registration.showNotification('¡Recordá completar tu registro alimentario!', {
  //           body: '',
  //           icon: 'icon-192x192.png', 
  //         });
  //       });
  //     }
  //   } else {
  //     console.error('No se encontró una suscripción.');
  //   }
  // }

  // const handleSendNotification = () => {
  //   sendNotificationToSubscriber();
  // };

root.render(
    <BrowserRouter>
    <App />
    {/* <button onClick={handleSendNotification}>Enviar Notificación</button> */}
    </BrowserRouter>
);

serviceWorkerRegistration.register();