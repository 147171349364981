import Config from "../config.js";

import { handle401 } from "./Util";


export const ApiComunicator = ( urlTail, info , method, isAnDownload = false) => {
  return new Promise((resolve, reject) => {
    let url = Config.endpoint + urlTail;
    const params = {
      credentials: "include",
      method: method,
      mode: 'cors',
      headers: new Headers({
      'Content-Type': 'application/json;charset=utf-8'
      })
    };

    if (info != null) {
      params.body = JSON.stringify(info);
    }

    fetch(url, params)
      .then((response) => {
        // console.log("API response",response);
        if (response.ok) {
          if(isAnDownload === true || response.status === 204){
            return response;
          } else{
            return response.json();
          }
        } else {
            throw response;
        }
      })
      .then(resolve)
      .catch((error) => {
        // console.log("API error",typeof error,error.status);
        // let genericError = {errors:[{detail:"Ha ocurrido un error al hacer la petición"}]};
        let genericError = error;
        if (error.status === 401) handle401(); //Se vencio el token / No autorizado
        if (error.status >= 404 && error.status <= 499) return genericError;
        if (typeof error.json !== 'function') return genericError;

        return error.json()
      })
      .then(reject);
  });
};

/* const axios = require('axios');

export const ApiPost =  async (ruta, body, token) => {
  let url = Config.endpoint + ruta;
  const params = {
    credentials: "include",
    mode: 'cors',
    headers:{
    'Content-Type': 'application/json;charset=utf-8',
    }
  };
  const res = await axios.post(
    url, body, params 
  )
  .catch(error => {
    //console.log(error)
    throw (error.response)
  });
  return res;
};

export const ApiGet =  async (ruta, token) => {
  let url = Config.endpoint + ruta;
  const params = {
    withCredentials: true,
    credentials: 'include',
    mode: 'cors',
    headers: {
    'Content-Type': 'application/json;charset=utf-8',

    }
  };
  const res = await axios.get(
    url, params
  ).catch(error => {
    //console.log(error)
  });
  return res;
};
 */