import { css } from "@emotion/react";
import ClipLoader from "react-spinners/SyncLoader";
import './spinner.css'


const Spinner =  ({loading}) => {
  return (
    <div className="container fill">
    <div className="row mb-5 justify-content-center mb-2 align-middle">
        <div className="col-12 col-lg-12">
            <ClipLoader color={"#a383ac"} loading={loading} size={20} />
        </div>
    </div>
    </div>
  );
}

export default Spinner;
