
import React , {useState, useEffect} from "react";
import { BiWindows } from "react-icons/bi";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
//Spinner
import Spinner from "./Components/spinner";
import { ApiComunicator } from './Helpers/ApiComunicator';

import './custom.scss';

//Layouts
const LayoutCards  = React.lazy(() => import('./Layout/layoutCards'));
//const Layout  = React.lazy(() => import('./Layout/layout'));
const Layout  = React.lazy(() => import('./Layout/layout'));
//Vistas
const Login =  React.lazy(() => import('./Controllers/LoginController'));
const LoginClave = React.lazy(() => import('./Views/LoginClave'));
//const LoginCodigo = React.lazy(() => import('./Views/LoginCodigo'));
const Registro = React.lazy(() => import('./Views/LoginRegistro'));
const CambioPass = React.lazy(() => import('./Views/LoginCambioPass'));
const UsuarioExistente = React.lazy(() => import('./Views/LoginUsuarioExistente'));

const HomeSeguimiento = React.lazy(() => import('./Controllers/HomeSeguimientoController'));
const CategoriaDetalle =  React.lazy(() => import('./Controllers/CategoriaDetalleController'));
const Usuario =  React.lazy(() => import('./Controllers/UsuarioController'));
const Calendario =  React.lazy(() => import('./Controllers/CalendarioController'));
const FisicoDetalle =  React.lazy(() => import('./Controllers/FisicoDetalleController'));
const Notificaciones =  React.lazy(() => import('./Controllers/NotificacionesController'));
const LoginAs =  React.lazy(() => import('./Controllers/LoginAsController'));
const Administracion =  React.lazy(() => import('./Controllers/AdministracionController'));
const UsuarioAdmin =  React.lazy(() => import('./Controllers/UsuarioAdminController'));
const NuevoUsuario =  React.lazy(() => import('./Controllers/NuevoUsuarioController'));
const UsuarioPass =  React.lazy(() => import('./Controllers/UsuarioPassController'));
const LoginRecuperar =  React.lazy(() => import('./Controllers/LoginRecuperarController'));
const PoliticaPrivacidad =  React.lazy(() => import('./Controllers/PoliticaPrivacidadController'));

function App() {
  const [isAuth, setIsAuth] = useState(true)
  const [fecha, setFecha] = useState(new Date())
  const [logueado, setLogueado] = useState(false);

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = atob(base64);
    const buffer = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; i++) {
      buffer[i] = rawData.charCodeAt(i);
    }
  
    return buffer;
  }

  useEffect(() => {
    if(localStorage.getItem("user_id") !== null)
    {
      setLogueado(true);
      logIn();
      // Verificar si el navegador soporta notificaciones

      if ('Notification' in window) {
        if (Notification.permission !== 'granted') {
          Notification.requestPermission().then((permission) => {

            if (permission === 'granted') {
              console.log('Permiso para notificaciones ok.');

              navigator.serviceWorker.ready.then((registration) => {

                console.log("registration", registration)
                registration.pushManager.getSubscription().then((subscription) => {

                  if (subscription) {
                    console.log("existe", subscription)
                    // La suscripción ya existe, la guardo en localStorage
                    const subscriptionJSON = JSON.stringify(subscription);

                          try {
                            let body = {
                              subscriptionJSON : subscriptionJSON ,
                              idUsuario : parseInt(localStorage.getItem("user_id"))
                            }
                            console.log(body)
                            ApiComunicator('/notificaciones/actualizarSuscripcion', body, "POST")
                            .then(response => {
                              console.log("response actualizar", response)
                              
                            })
                            .catch(function (error) {
                              console.log(error)
                            });
                          }
                          catch (error) {
                            console.log(error)
                          }
                    
                    // localStorage.setItem('pushSubscription', subscriptionJSON);
                    // console.log("subscriptionJSON", subscriptionJSON)
                    // console.log("id", localStorage.getItem("user_id"))

                  } else {
                    console.log("no existe")
                    // No hay una suscripción existente, creo una
                    const applicationServerKey = 'BEc01N3rBYmMMV6JUiFaEWNl6Mm4By0puAPaccY1raL2XsWTqqE5cBDpt3gAfeM3f-H5dpA6X0gMWbVN9H0xNNs';
                    
                    registration.pushManager
                      .subscribe({
                        userVisibleOnly: true,
                        applicationServerKey:  urlBase64ToUint8Array(applicationServerKey),
                      })
                      .then((newSubscription) => {
                        const subscriptionJSON = JSON.stringify(newSubscription);
                        // localStorage.setItem('pushSubscription', subscriptionJSON);
                        console.log("subscriptionJSON", subscriptionJSON)
                        console.log("id", localStorage.getItem("user_id"))

                        try {
                            let body = {
                              subscriptionJSON : subscriptionJSON ,
                              idUsuario : parseInt(localStorage.getItem("user_id"))
                            }
                            console.log(body)
                            ApiComunicator('/notificaciones/insertSuscripcion', body, "POST")
                            .then(response => {
                              console.log("response insert", response)
                              
                            })
                            .catch(function (error) {
                              console.log(error)
                            });
                          }
                          catch (error) {
                            console.log(error)
                        }

                      })
                      .catch((error) => {
                        console.error('Error al suscribirse a las notificaciones:', error);
                      });

                  }

                });
              });
            }
          });
        }
      }

    }

  }, []); // INICIAL
 
  const logIn = () => setIsAuth(true);
  const logOut = () => {
    console.log("Bye")
    localStorage.clear() //El único momento que meto el clear por la cookie
    setIsAuth(false)
  };

  function sendNotificationToSubscriber() {
    const subscriptionJSON = localStorage.getItem('pushSubscription');
    if (subscriptionJSON) {
      const subscription = JSON.parse(subscriptionJSON);
  
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification('¡Recordá completar tu registro alimentario!', {
            body: '',
            icon: 'icon-192x192.png', 
          });
        });
      }
    } else {
      console.error('No se encontró una suscripción.');
    }
  }

  return (
   
    <>
    <Routes forceRefresh={true}>
        {/* No Autenticadas */}
        <Route path="/login" element={<React.Suspense fallback={<Spinner/>}><Layout isHeader={false} isFooter={false} fecha ={fecha}><Login logIn={logIn} logOut={logOut} /></Layout></React.Suspense>}></Route>
        <Route path="/spinner" element={<React.Suspense fallback={<Spinner/>}><Spinner / ></React.Suspense>}></Route>
        <Route path="/recuperar" element={<React.Suspense fallback={<Spinner/>}><Layout isHeader={false} isFooter={false} ><LoginRecuperar /></Layout></React.Suspense>}></Route>
        <Route path="/cambio-pass/:secCode" element={<React.Suspense fallback={<Spinner/>}><Layout isHeader={false} isFooter={false} ><CambioPass/></Layout></React.Suspense>}></Route>d
        {/* <Route path="/login-auth" element={<React.Suspense fallback={<Spinner/>}><Layout isHeader={false} isFooter={false} ><LoginClave /></Layout></React.Suspense>}></Route>
        <Route path="/login-code" element={<React.Suspense fallback={<Spinner/>}><Layout isHeader={false} isFooter={false} ><LoginCodigo /></Layout></React.Suspense>}></Route>
        <Route path="/registrarme" element={<React.Suspense fallback={<Spinner/>}><Layout isHeader={false} isFooter={false} ><Registro /></Layout></React.Suspense>}></Route>
        <Route path="/cambio-pass/:secCode" element={<React.Suspense fallback={<Spinner/>}><Layout isHeader={false} isFooter={false} ><CambioPass/></Layout></React.Suspense>}></Route>
        <Route path="/login-existe" element={<React.Suspense fallback={<Spinner/>}><Layout isHeader={false} isFooter={false} ><UsuarioExistente /></Layout></React.Suspense>}></Route> */}
         { /*Autenticadas */}
        
        <Route forceRefresh={true} path="/seguimiento-alimentario" element={isAuth ? <React.Suspense fallback={<Spinner/>}><LayoutCards isHeader={true} isFooter={true} fecha ={fecha}> <HomeSeguimiento fecha={fecha}/> </LayoutCards></React.Suspense> : <Navigate to="/login" replace />}></Route>
        <Route path="/actividad-fisica" element={isAuth ? <React.Suspense fallback={<Spinner/>}><LayoutCards isHeader={true} isFooter={true} fecha ={fecha}> <FisicoDetalle fecha ={fecha} /> </LayoutCards></React.Suspense> : <Navigate to="/login" replace />}></Route>
        <Route path="/seguimiento-alimentario/detalle" element={isAuth ? <React.Suspense fallback={<Spinner/>}><LayoutCards isHeader={true} isFooter={true} fecha ={fecha}> <CategoriaDetalle fecha ={fecha}/> </LayoutCards></React.Suspense> : <Navigate to="/login" replace />}></Route>
        <Route path="/mi-perfil" element={isAuth ? <React.Suspense fallback={<Spinner/>}><LayoutCards isHeader={true} isFooter={true} fecha ={fecha}> <Usuario logOut={logOut}/> </LayoutCards></React.Suspense> : <Navigate to="/login" replace />}></Route>
        <Route path="/calendario" element={isAuth ? <React.Suspense fallback={<Spinner/>}><LayoutCards isHeader={true} isFooter={true} fecha ={fecha}> <Calendario fecha={fecha} setFecha ={setFecha}/> </LayoutCards></React.Suspense> : <Navigate to="/login" replace />}></Route>
        <Route path="/notificaciones" element={isAuth ? <React.Suspense fallback={<Spinner/>}><LayoutCards isHeader={true} isFooter={true} fecha ={fecha}> <Notificaciones fecha={fecha} /> </LayoutCards></React.Suspense> : <Navigate to="/login" replace />}></Route>
        <Route path="/ver-como" element={isAuth ? <React.Suspense fallback={<Spinner/>}><LayoutCards isHeader={true} isFooter={true} fecha ={fecha}> <LoginAs fecha={fecha} /> </LayoutCards></React.Suspense> : <Navigate to="/login" replace />}></Route>
        
        <Route path="/politica-privacidad" element={<React.Suspense fallback={<Spinner/>}> <PoliticaPrivacidad /> </React.Suspense>}></Route>
        
        <Route path="/administracion" element={isAuth ? <React.Suspense fallback={<Spinner/>}><LayoutCards isHeader={true} isFooter={true} fecha ={fecha}> <Administracion fecha={fecha} /> </LayoutCards></React.Suspense> : <Navigate to="/login" replace />}></Route>
        <Route path="/usuario" element={isAuth ? <React.Suspense fallback={<Spinner/>}><LayoutCards isHeader={true} isFooter={true} fecha ={fecha}> <UsuarioAdmin fecha={fecha} /> </LayoutCards></React.Suspense> : <Navigate to="/login" replace />}></Route>
        <Route path="/usuario/nuevo" element={isAuth ? <React.Suspense fallback={<Spinner/>}><LayoutCards isHeader={true} isFooter={true} fecha ={fecha}> <NuevoUsuario fecha={fecha} /> </LayoutCards></React.Suspense> : <Navigate to="/login" replace />}></Route>
        <Route path="/usuario/cambiar-pass" element={isAuth ? <React.Suspense fallback={<Spinner/>}><LayoutCards isHeader={true} isFooter={true} fecha ={fecha}> <UsuarioPass logOut={logOut}/> </LayoutCards></React.Suspense> : <Navigate to="/login" replace />}></Route>
        <Route path="/" element={ <Navigate to="/home" replace />} ></Route>
        <Route path="*" element={ <Navigate to="/login" replace />} ></Route>
    </Routes>
    </>
  );
}

export default App;
